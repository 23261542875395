import * as React from "react"
import {graphql, Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


const FederalStandardDeduction = ({data}) => {
  const deductions2021 = data.stdDeductions2021.nodes;
  const deductions2020 = data.stdDeductions2020.nodes;

  return (
    <Layout>
      <SEO title="Federal Standard Deduction"/>
      <div className="container ">
        <h1 className="pt-3">IRS Standard Tax Deductions 2020 and 2021</h1>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <p>The standard deduction is a dollar amount that reduces your total taxable income.
              In 2020 the standard deduction is $12,400 for single filers and married filing separately,
              $18,650 for head of household, and $24,800 for married filing jointly.</p>

            <p>In 2021 the standard deduction is $12,550 for single filers and married filing separately,
              $18,800 for head of household, and $25,100 for joint filers.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h3>Standard Tax Deductions Tax Year 2021: 01/01/21 - 12/31/21</h3>
            <p>These standard deduction rates apply for this calendar year 2021 with due tax returns in 2022</p>
            <table className="table table-striped">
              <thead className="thead-dark">
                <th scope="col" className="pl-3">Filing Status</th>
                <th scope="col">Age</th>
                <th scope="col">Standard Deduction</th>
              </thead>
              <tbody>
              {deductions2021.map(deduction => {

                const { Filing_Status, Age, Standard_Deduction } = deduction;

                return (
                  <tr>
                    <td className="pl-3">{Filing_Status}</td>
                    <td>{Age}</td>
                    <td>{Standard_Deduction}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">

            <h3>Standard Tax Deductions Tax Year 2020: 01/01/20 - 12/31/20</h3>
            <p>These standard deduction rates apply for this calendar year 2020 with due tax returns in 2021</p>
            <table className="table table-striped">
              <thead className="thead-dark">
              <th scope="col" className="pl-3">Filing Status</th>
              <th scope="col">Age</th>
              <th scope="col">Standard Deduction</th>
              </thead>
              <tbody>
              {deductions2020.map(deduction => {

                const { Filing_Status, Age, Standard_Deduction } = deduction;

                return (
                  <tr>
                    <td className="pl-3">{Filing_Status}</td>
                    <td>{Age}</td>
                    <td>{Standard_Deduction}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <h3>How the Standard Deduction Works</h3>

            <p>The IRS lets you take the standard deduction on a no-questions-asked basis. The standard deduction
              reduces the amount of income you have to pay taxes on.</p>

            <p>You can either take the standard deduction or itemize on your tax return — however, you can't apply both
              to your taxes. Itemized deductions are expenses allowed by the IRS that can decrease your taxable income.</p>

            <p>By taking the standard deduction you will then not be able to deduct home mortgage interest or take the many other
              popular tax deductions such as medical expenses or charitable donations. If you go with itemized deductions then
              you should keep detailed records to back up your deductions in case the IRS decides to audit you.</p>


          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <Link to="/">Go back to the homepage</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default FederalStandardDeduction;


export const pageQuery = graphql`
  query {
    stdDeductions2021: allFederalStandardDeductionCsv(filter: {Year: {eq: "2021"}}) {
      nodes {
        Standard_Deduction
        Age
        Filing_Status
      }
    }
    stdDeductions2020: allFederalStandardDeductionCsv(filter: {Year: {eq: "2020"}}) {
      nodes {
        Standard_Deduction
        Age
        Filing_Status
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
